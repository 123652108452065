<template>
	<div class="home"></div>
</template>

<script>
// @ is an alias to /src
export default {
	name: 'Home',
	components: {},
	mounted() {
		this.getUserWechatInfo();
	},
	methods: {
		async getUserWechatInfo() {
			let that = this;
			let code = this.$utils.getUrlKey('code');
			let state = this.$utils.getUrlKey('state');
			console.log('[code:]----------', code);
			console.log('[state:]---------', state);
			if (code) {
				let openId = await this.$wechatUtils.getWechatInfo(code, state);
				console.log('this.openId----------', openId);
				let redirectUrl = await this.$wechatUtils.getRedirectUrlWithState(state);
				console.log("redirectUrl----------", redirectUrl);
				console.log("final", redirectUrl + "?state="+ state +"&openId="+openId);
				if (redirectUrl){
					window.location.replace(redirectUrl + "?state="+ state +"&openId="+openId);
				}
			} else {
				this.$wechatUtils.redirectToAuthPage(state);
			}
		}
	}
};
</script>
