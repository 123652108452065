import axios from 'axios'

let base_url = 'https://wechatsever.maopaisz.com/wechatRouter';
// let base_url = 'http://192.168.50.248:3333/wechatRouter';

function getUrlKey(name, string) {
	return (
		decodeURIComponent(
			(new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
				string
			) || [, ''])[1].replace(/\+/g, '%20')
		) || null
	);
}

function redirectToAuthPage(state) {
	console.log("[wechatutil-----------redirectToAuthPage]");
	const local = window.location.href;
	console.log("local----------", local);
	window.location.replace('https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3b0be6bf67633130&redirect_uri=' +
		encodeURIComponent(local.split('#')[0]) +
		'&response_type=code&scope=snsapi_base&state=' + state + '#wechat_redirect');
}

async function getWechatInfo(code, state) {
	let that = this;
	return await axios
		.post(base_url + '/getWechatInfoWithCode', {
			code
		})
		.then(function(response) {
			console.log("[getWechatInfo]---[response.data.data]-----------", response.data.data);
			if (response.data.data.openId) {
				return response.data.data.openId;
			}
		})
		.catch(function(error) {
			console.log(error);
		});
}

async function getConfigJson() {
	// console.log("url...........", location.href.split('#')[0]);
	// console.log("encode url...........", encodeURIComponent(location.href.split('#')[0]));
	// console.log("getConfigJson");
	let that = this;
	return await axios
		.post(base_url + '/getConfigJson', {
			// .post('http://192.168.1.109:3333/hisenseAct/getConfigJson', {
			url: encodeURIComponent(location.href.split('#')[0])
		})
		.then(function(response) {
			// console.log("getConfigJson.....response....", response.data.data);
			if (response.data.data) {
				return response.data.data;
			}
		})
		.catch(function(error) {
			console.log(error);
		});
}

async function getRedirectUrlWithState(state){
	let that = this;
	return await axios
		.post(base_url + '/getRedirectUrlWithState', {
			state
		})
		.then(function(response) {
			if (response.data.data.redirectUrl) {
				return response.data.data.redirectUrl;
			} else {
				return null;
			}
		})
		.catch(function(error) {
			console.log(error);
		});
}

async function initWxConfig(code) {
	// console.log("initWxConfig");
	let json = await getConfigJson(code);
	// console.log("appId", json.appId);
	// console.log("sign", json.signature);
	// console.log("onceStr", json.nonceStr);
	// console.log("timeStamp", json.timestamp);
	wx.config({
		debug: false,
		appId: json.appId,
		timestamp: json.timestamp,
		nonceStr: json.nonceStr,
		signature: json.signature,
		jsApiList: ["scanQRCode"]
	});

	wx.ready(function() {
		console.log("wx.ready()");
	});

	wx.error(function(res) {
		console.log("wx.error()", res);
	});
}

export default {
	redirectToAuthPage,
	initWxConfig,
	getUrlKey,
	getWechatInfo,
	getRedirectUrlWithState
};
