import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import utils from './utils/urlGet.js'; //获取url参数
import wechatUtils from './utils/wechatUtil.js' //获取微信工具
import Vconsole from 'vconsole'
// Vue.prototype.$vConsole = new Vconsole();
Vue.config.productionTip = false

window.addEventListener('popstate', function() {
 history.pushState(null, null, document.URL)
})

Vue.prototype.$axios = axios
Vue.prototype.$utils = utils;
Vue.prototype.$wechatUtils = wechatUtils;

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
